.react-tags {
    position: relative;
    padding: 6px 0 0 6px;
    border: 1px solid #D1D1D1;
    border-radius: 1px;
  
    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;
  
    /* clicking anywhere will focus the input */
    cursor: text;
  }
  
  .react-tags.is-focused {
    border-color: #B1B1B1;
  }
  
  .react-tags__selected {
    display: inline;
  }
  
  .react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    background: #F1F1F1;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  
  .react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
  }
  
  .react-tags__selected-tag:hover,
  .react-tags__selected-tag:focus {
    border-color: #B1B1B1;
  }
  
  .react-tags__search {
    display: inline-block;
  
    /* match tag layout */
    padding: 0.125em;
    margin-bottom: 0;
  
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__search {
      /* this will become the offsetParent for suggestions */
      position: relative;
    }
  
  }
  
  .react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
  
    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
  
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
  }
  
  .react-tags__search-input::-ms-clear {
    display: none;
  }
  
  .react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10000;
  }
  
  @media screen and (min-width: 30em) {
  
    .react-tags__suggestions {
      width: 15em;
    }
  
  }
  
  .react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
  }
  
  .react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  
  .react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
  }
  
  .react-tags__suggestions li.is-active {
    background: #b7cfe0;
  }
  
  .react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
  }
:root {
  --dark-green: #006532;
  --red-label: #990000;
  --dark-grey: #999999;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;

  font-size: 1rem;
  line-height: 1.5;
}

body,
h1,
h2,
h3,
h4,
.icon .label,
h5 {
  font-family: "Montserrat", sans-serif;
}

#root div.ui.container {
  width: 92%;
}

#root header .main-nav div.ui.container {
  width: 100%;
}

#root .error {
  color: #990000;
  color: var(--red-label);
}

.main-nav i.out {
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.main-nav i.out:hover {
  -webkit-transform: scale(1.25) translate(10%, 0);
          transform: scale(1.25) translate(10%, 0);
}

button:disabled {
  opacity: 0.5;
}

ul,
ol,
figure {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
}

figure {
  margin: 0;
  max-width: 100%;
}

ul.menu {
  margin: 0 0 0 2em;
  padding: 0;
  list-style: none;
}

header ul.main-menu {
  float: right;
  display: flex;
  flex-flow: row nowrap;
  justify-items: flex-end;
  align-items: flex-end;
}

header ul.main-menu li {
  margin-left: 1em;
  white-space: nowrap;
}

ul.main-menu li {
  list-style: none;
  font-size: 1em;
  text-transform: uppercase;
}

header nav.main-nav a {
  display: block;
}

header nav.main-nav i.icon,
header nav.main-nav a {
  position: relative;
}

header nav.main-nav {
  float: right;
  min-width: 72%;
  margin-left: 2%;
  max-width: 72%;
  overflow: hidden;
}

header nav.main-nav ul li.active a {
  font-style: italic;
}

header nav.main-nav ul li:hover a {
  text-decoration: underline;
}

header nav.main-nav ul li:hover a.contribute {
  text-decoration: none;
  color: white;
  background-color: black;
}

header nav.main-nav ul li i.icon {
  margin: 0 0 0 0.5em;
  font-size: 0.75em;
  top: -0.125em;
}

i.icon,
header nav.main-nav ul li .logged-out,
.top-bar .bulk-actions span,
.logged-in header nav.main-nav ul li .logged-in {
  display: inline-block;
}

.logged-in header nav.main-nav ul li .logged-out,
header nav.main-nav ul li .logged-in {
  display: none;
}

.main-area,
aside {
  position: relative;
}

.top-bar .bulk-actions .icon,
.status-options li {
  cursor: pointer;
}

.segments > h2 {
  float: left;
  width: 100%;
}

.segments > h2 .delete {
  color: red;
  -webkit-filter: grayscale(66.667%);
          filter: grayscale(66.667%);
  transition: -webkit-filter 0.33s ease-in-out;
  transition: filter 0.33s ease-in-out;
  transition: filter 0.33s ease-in-out, -webkit-filter 0.33s ease-in-out;
}

.segments > h2 .delete:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

@media screen and (min-width: 880px) {
  .segments > h2 {
    float: left;
    min-width: 16em;
    width: auto;
  }
}

.segments > h2 .filter {
  display: inline-block;
  margin-left: 1em;
  font-size: 0.8em;
}

.segments > p.filter {
  width: 50%;
  max-width: 20em;
  float: left;
}

.container .segments.artists-listing {
  padding-top: 1.25em;
}

.container .segments.artists-listing > h3 {
  float: left;
}

.container .segments.artists-listing > h3 small {
  font-weight: normal;
}

.container .segments.artists-listing > h3 strong {
  font-weight: bold;
}

.segments > p.filter input.textfield {
  width: 100%;
}

.main-images figure ul.curations {
  position: absolute;
  top: 4em;
  left: -2em;
  margin: 0;
  list-style-type: none;
  padding: 1em;
  max-width: 20em;
  opacity: 0;
  transition: opacity 0.33s ease-in-out;
  height: calc(100vh - 7em);
  overflow-y: auto;
}

@media (min-width: 1280px) {
  .main-images figure ul.curations {
    max-width: 25em;
  }
}

@media (min-width: 1440px) {
  .main-images figure ul.curations {
    max-width: 30em;
  }
}

.main-images figure:hover ul.curations {
  opacity: 1;
}

.main-images figure .curations li {
  position: relative;
  background-color: rgba(255, 255, 255, 0.75);
  margin: 0.5em 0;
  padding: 0.125em 3.5em 0.125em 0.5em;
  border-bottom-right-radius: 1em;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.main-images figure .curations li.curation {
  
  background-color: rgba(255, 238, 238, 0.75);
}

.main-images figure .curations li.folder {
  background-color: rgba(204, 238, 255, 0.75);
}

figure .curations li em,
figure ul.curations li i.icon {
  position: absolute;
  top: 0;
}

figure .curations li em {
  right: 2em;
  padding: 0.125em;
}

figure ul.curations li i.icon {
  right: 0.25em;
  color: green;
}

.toggle-approve {
  display: inline-block;
  margin: 0.25em;
  cursor: pointer;
}

.main-images figure .curations li:hover {
  background-color: rgba(255, 255, 0, 0.75);
}

.main-images figure .curations li.folder:hover {
  background-color: rgba(204, 255, 204, 0.75);
}

.contributor-images .top-bar em,
.contributor-images .top-bar span,
.main-images figure .curations li span,
.main-images figure .curations li em,
.user-edit-form .inner-wrapper > p strong,
.user-edit-form .inner-wrapper > p em,
.user-edit-form .inner-wrapper > p span {
  display: inline-block;
}

.user-edit-form .inner-wrapper > p em {
  margin-right: 2em;
}

.user-edit-form .inner-wrapper > p strong {
  margin-right: 0.25em;
}

.contributor-images .top-bar h1 {
  cursor: pointer;
}

i.icon {
  transition: all 0.5s ease-in-out;
  display: inline-block;
}

.contributor-images .top-bar h1:hover i.icon {
  color: green;
}

.contributor-images .top-bar h1 i.refresh {
  position: relative;
  height: 1em;
  width: 1em;
  -webkit-transform-origin: 50% 62.5%;
          transform-origin: 50% 62.5%;
}

.contributor-images .top-bar h1 i.refresh:before {
  position: absolute;
  top: 0;
  left: 0;
}

.contributor-images .top-bar h1 i.refresh:hover {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.contributor-images .top-bar em {
  margin-left: 0.5em;
  font-size: 0.8em;
}
.contributor-images .top-bar span {
  margin-right: 0.25em;
}

@media screen and (min-width: 880px) {
  .segments > p.filter {
    float: left;
    margin-left: 5%;
  }
}

.user-edit-form > .actions,
.segments > .actions {
  float: right;
}

span.checkbox-item {
  display: inline-block;
  margin-left: 1em;
}

.checkbox-item i,
.checkbox-item label {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.status-options li:hover {
  text-decoration: underline;
}

.status-options li.active {
  font-style: italic;
}

.right-col-offset .main-content .main-area {
  width: 66.6667%;
  float: left;
  padding: 1.5em 0;
}

.right-col-offset .main-content .main-area .wp-block-image img,
.right-col-offset .main-content .main-area img.large {
  width: 100%;
  height: auto;
}

.right-col-offset .main-content > aside {
  width: 30%;
  float: right;
  top: -6em;
}

.right-col-offset .main-content > .main-area > h2,
.right-col-offset .main-content > aside > h1 {
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0;
  text-align: right;
}

.right-col-offset .main-content > .main-area > h2 {
  width: 100%;
}

.right-col-offset .main-content > aside > h1 {
  position: absolute;
  top: 16em;
  left: 0;
  -webkit-transform: rotate(-90deg) translate(0, -0.5em);
          transform: rotate(-90deg) translate(0, -0.5em);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  width: 16em;
}

.right-col-offset .main-content > aside > article {
  margin-left: 3rem;
}

@media screen and (min-width: 1024px) {
  header nav.main-nav {
    max-width: 60%;
  }
  header ul.main-menu {
    margin-left: 3em;
  }
}

ul.main-menu li a {
  color: black;
  display: block;
  padding: 0;
}

.wrapper,
#root {
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.top-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5em;
  background-color: white;
  z-index: 10;
}

.main-wrapper {
  margin-top: 6em;
}

.wrapper {
  max-width: 100%;
}

.wrapper .inner-wrapper {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
}

.wrapper > header {
  padding: 1.5em 5% 1em 5%;
}

i.google:hover {
  color: #cc0000;
}

i.facebook:hover {
  color: #000099;
}

header .user-details i.icon,
.login-fields i.icon {
  margin-left: 0.5em;
}

header .user-details i.icon {
  font-size: 1.25rem;
}

.login-fields i.icon {
  font-size: 2rem;
  transition: color 0.5s ease-in-out;
  cursor: pointer;
}

.login-form p {
  clear: both;
}

.container .horizontal {
  display: flex;
  flex-flow: row wrap;
  border: none;
  box-shadow: none;
}

#root .segment,
#root .ui.segments {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.container #image-list {
}

#root .login-form,
#root .user-details {
  height: 2em;
  display: flex;
  margin: 1.5em 0;
  clear: both;
  float: right;
}

#root .user-details {
  flex-flow: row nowrap;
}

#root .login-form {
  flex-flow: column nowrap;
}

#root .login-form > .login-fields {
}

#root .user-details img {
  height: 100%;
  width: auto;
}

#root .login-form > .login-fields,
#root .user-details > i,
#root .user-details > button {
  margin-left: 1em;
}

#root .user-details i,
#root .user-details button,
#root .user-details span {
  display: inline-block;
  white-space: nowrap;
}

#root .user-details > .user-display {
  display: inline-block;
  font-size: 0.75em;
  margin: 0.5em;
  text-align: right;
}

header i.icon {
  cursor: pointer;
}

header .logo {
  float: left;
  width: 24%;
  height: 3em;
  background-image: url(/images/tgi-tree.svg);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
}

.logo figcaption {
  position: absolute;
  top: 0;
  left: -999em;
}

#root .wrapper main {
  clear: both;
}

.curation-images,
.container ol.paginator {
  list-style-type: none;
}

.container ol.paginator {
  display: flex;
  flex-flow: row nowrap;
  clear: both;
  margin: 1em 0;
  padding: 0;
}

.curation-images {
  margin: 0;
  padding: 1em 0;
}

.curation-images li {
  margin: 1em auto;
  clear: both;
}

table.ui.table thead .sortable {
  cursor: pointer;
}

table thead .sortable:hover {
  background-color: #ffffdd;
  -webkit-filter: hue-rotate(30deg);
          filter: hue-rotate(30deg);
}

table.displayName thead th.displayName,
table.identifier thead th.identifier,
table.numImages thead th.numImages,
table.created thead th.created,
table.login thead th.login {
  background-color: #ffffbb;
}

table.displayName td.displayName,
table.identifier td.identifier,
table.numImages td.numImages,
table.created td.created,
table.login td.login {
  background-color: #ffffdd;
}

.sortable-item {
  position: relative;
  pointer-events: none;
  clear: both;
}

.sortable-item div.handle,
.sortable-item i {
  pointer-events: all;
  cursor: pointer;
}

.sortable-item img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.sortable-item img,
#root img.sortable-image {
  max-width: 320px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

#root li.sortable-item.highlighted img {
  -webkit-transform: scale(2);
          transform: scale(2);
  pointer-events: all;
}

#root .bulk-actions .icon.on-state,
#root figure.approved > .icon.star,
#root .promoted .icon.star {
  color: #33ff66;
}

#root .bulk-actions .icon.off-state {
  color: #990000;
}

body > li.sortable-item,
.curation-images li.sortable-item,
#root li.sortable-item {
  border: solid 1px rgba(0, 0, 0, 0.5);
  margin: 1em auto;
  background: rgba(255, 255, 255, 0.5);
}

#root li.sortable-item.selected {
  background: rgba(153, 255, 255, 0.25);
}

.curation-images li.sortable-item .info,
body li.sortable-item .info,
#root li.sortable-item .info {
  position: relative;
  padding: 1em;
  margin-left: 15em;
}

body li.sortable-item .info ul.tags {
  display: flex;
  flex-flow: row wrap;
}

body li.sortable-item .info ul.tags li {
  margin: 0 2.5em 0 0;
}

li.sortable-item .info p {
  margin: 0;
  padding: 0 0 0.5em 0;
}

li.sortable-item > i.icon {
  display: none;
}

li.sortable-item img {
  transition: -webkit-filter 0.5s ease-in-out;
  transition: filter 0.5s ease-in-out;
  transition: filter 0.5s ease-in-out, -webkit-filter 0.5s ease-in-out;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

li.sortable-item:hover img,
li.sortable-item.selected img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
body > li.sortable-item .handle .arrows,
li.sortable-item .handle .arrows {
  transition: all 0.5s ease-in-out;
}

li.sortable-item .handle .arrows {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}

li.sortable-item.selected .handle .arrows {
  -webkit-transform: scale(1);
          transform: scale(1);
}

body > li.sortable-item img {
  -webkit-filter: hue-rotate(180deg);
          filter: hue-rotate(180deg);
}

body > li.sortable-item .handle .arrows {
  -webkit-transform: scale(1.33);
          transform: scale(1.33);
  opacity: 0.75;
}

li.sortable-item .handle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 320px;
}

li.sortable-item .handle .arrows {
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 137.5%;
  margin: -4rem 0 0 -2rem;
  font-size: 5rem;
}

li.sortable-item .handle:hover .icon {
  opacity: 0.75;
}

body  .sortable-item,
.curation-images > li {
  display: flex;
  flex-flow: row nowrap;
}

.curation-images>li img {
  max-width: 320px;
  align-self: flex-start;
}

.curation-images li .likes {
  height: 2em;
}

.curation-images li.sortable-item > i.icon {
  display: block;
  position: absolute;
  left: 320px;
  font-size: 1.5rem;
  height: 2em;
  width: 1em;
  padding: 0;
  margin: 0 0 0 2rem;
}

.curation-images li.sortable-item.highlighted {
  background: white;
  z-index: 20;
}

.curation-images li.sortable-item.highlighted i.icon.square,
.curation-images li.sortable-item.highlighted div.handle {
  display: none;
}

.curation-images li.sortable-item.highlighted > i.icon {
  left: 640px;
  transition: left 0.5s ease-in-out;
}

.curation-images li.sortable-item > i.icon.square {
  top: 2.5em;
}

.curation-images li.sortable-item > i.icon.zoom {
  top: 0.5em;
}

.container ol.paginator li {
  margin: 0;
  padding: 0 1em;
}

.edit-form {
  position: relative;
  clear: both;
  margin-top: 0.5em;
}

#root .segments > .actions i.icon.right {
  font-size: 3rem;
}

#root .segments > .actions > i.icon {
  font-size: 2.5rem;
  margin-right: 1em;
}

@media screen and (min-width: 880px) {
  .edit-form div.actions {
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
    margin-left: 2em;
  }

  #root .edit-form div.actions i.icon:before {
    font-size: 3rem;
  }
}

.edit-form .textfield,
.edit-form textarea {
  max-width: 40em;
}

.edit-form textarea {
  width: 100%;
}

#image-list figure.segment {
  position: relative;
  display: block;
  width: auto;
  max-width: 100%;
  flex: 1 1 auto;
  padding: 0.5%;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  height: auto;
  border: none;
  box-shadow: none;
  font-size: 0;
  overflow: hidden;
  z-index: 2;
  max-width: 100%;
  min-height: 6rem;
}

#image-list figure.segment > i.status-icon,
#image-list figure.segment > i.square,
#image-list figure.segment > i.star,
#image-list figure.segment > .photo-overlay {
  position: absolute;
  cursor: pointer;
}

#image-list figure.segment > i.star {
  top: 0.375em;
  right: 2.25em;
  font-size: 1rem;
}

#image-list figure.segment > i.status-icon {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  font-size: 0.8rem;
  margin-top: 0.125em;
  height: 1.5em;
  width: 1.5em;
}

#image-list figure.segment > .photo-overlay {
  top: 15%;
  left: 15%;
  right: 15%;
  bottom: 15%;
  border-radius: 50%;
  border: solid 1px transparent;
  cursor: zoom-in;
}

#image-list figure.segment > .photo-overlay:hover {
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
  border: solid 2px rgba(255, 255, 255, 0.25);
}

#image-list figure.segment > i.status-icon,
#image-list figure.segment > i.square {
  top: 0.5rem;
}

#image-list figure.segment > i.square {
  font-size: 1rem;
  color: white;
}

#image-list figure.segment > i.check {
  color: #99ff99;
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

#image-list figure.segment > i.square {
  left: 0.5rem;
}

#image-list figure.segment > i.status-icon {
  right: 0.5rem;
}

#root .horizontal > figure.segment.active {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 51;
  padding: 0;
}

#image-list figure.segment figcaption {
  font-size: 0.75rem;
}

#root .actions i.icon {
  font-size: 1.5rem;
  cursor: pointer;
  width: auto;
}

#root .actions i.icon .label {
  display: inline-block;
  font-size: 0.667em;
  margin-left: 0.25em;
  -webkit-user-select: none;
          user-select: none;
}

#image-list figure i.icon.thumbs.down::before {
  color: #990000;
  color: var(--red-label);
}

#root table td.actions {
  text-align: right;
}

#root table thead th.likes {
  height: 4em;
  max-width: 4em;
}

#root table thead th.likes .text {
  position: relative;
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  white-space: nowrap;
}

#root table td.actions i.icon {
  font-size: 1rem;
  margin-left: 0.75em;
}

#root .actions i.icon.close {
  margin-left: 1em;
}

#root .horizontal > figure.segment.active figcaption {
  opacity: 0;
}

#root .main-images figure.active picture.xl,
#root .main-images figure.active.show-xl picture.large {
  opacity: 0;
  pointer-events: none;
}

#root .artist-caption,
#image-list > figure {
  position: relative;
}

#root .main-images figure.is-claimed::after,
#image-list > figure.is-claimed::after {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 2rem;
  content: "\1F590";
  opacity: 0.66667;
  -webkit-filter: hue-rotate(180deg);
          filter: hue-rotate(180deg);
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
          user-select: none;
}

#root .main-images figure.is-claimed.claim-approved::after,
#image-list > figure.is-claimed.claim-approved::after {
  -webkit-filter: hue-rotate(0deg);
          filter: hue-rotate(0deg);
  -webkit-transform: scale(1);
          transform: scale(1);
}

#root .main-images figure.is-claimed:hover::after,
#image-list > figure.is-claimed:hover::after {
  opacity: 1;
  -webkit-filter: hue-rotate(90deg) grayscale(0.5);
          filter: hue-rotate(90deg) grayscale(0.5);
  -webkit-transform: scale(-1, 1.2);
          transform: scale(-1, 1.2);
}

#root .main-images figure.is-claimed.claim-approved:hover::after,
#image-list > figure.is-claimed.claim-approved:hover::after {
  opacity: 1;
  -webkit-filter: hue-rotate(60deg) grayscale(0);
          filter: hue-rotate(60deg) grayscale(0);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

#root .artist-caption label {
  font-size: 0.75rem;
}

#root .artist-caption .copy {
  cursor: pointer;
  transition: color 0.5s ease-in-out;
  position: absolute;
  bottom: 0.75em;
  right: 0;
}

#root .artist-caption .copy:hover {
  color: green;
}

#root .artist-caption p {
  margin: 0.125rem auto 1rem auto;
  padding: 0.25em;
  border: dotted 1px gray;
}

#root .artist {
  margin: 0.5rem 0 1.25rem 0;
}

#root .artist h4 {
  margin: 0;
  font-size: 1em;
  padding: 0.5em 0 0 0;
}

#root .artist .checkbox-row {
  display: flex;
  flex-flow: row nowrap;
  align-items:flex-start;
  justify-content: flex-start;
}

#root .artist .checkbox-row i.icon {
  font-size: 2rem;
}

#root .artist .checkbox-row:hover i.icon {
  color: green;
}

#root .artist .textfield-long {
  margin: 0.125em 0;
}

#root .artist p.status {
  margin: 0.5em 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

#root .artist p.status label,
#root .artist p.status i {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

#root dl.twin-column {
  display: grid;
  grid-template-columns: 8em auto;
}

#root dl.twin-column dd {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}

#root dl.twin-column dd .thumbs.up {
  color: green;
}

#root dl.twin-column dd .thumbs.down {
  color: gray;
}

#root dl.twin-column dt {
  font-size: 0.75em;
  padding-right: 0.5em;
  text-align: right;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

#root .main-images figure.active > .react-draggable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.full-mode #root .main-images figure figcaption,
.full-mode #root .main-images figure .actions,
.full-mode #root .main-images figure .curations,
.full-mode #root .main-images > .icon {
  display: none;
}

#root .main-images figure.active.show-xl picture.xl,
#root .main-images figure.active picture.large {
  opacity: 1;
  -webkit-user-select: none;
          user-select: none;
}

figure .mid-overlay {
  position: absolute;
  top: 0;
  left: 25%;
  right: 25%;
  bottom: 40%;
  cursor: zoom-in;
  z-index: 127;
}

figure.show-xl .mid-overlay {
  left: 90%;
  right: 0;
  top: 0;
  bottom: 90%;
}

#root figure .mid-overlay .icon {
  position: absolute;
  top: -1vw;
  right: -2vw;
  font-size: 4vw;
  opacity: 0;
}

.full-mode #root figure.show-xl .mid-overlay .icon {
  opacity: 1;
}

#root table td {
  vertical-align: top;
}

#root table td.number {
  text-align: right;
}

#root table td.large {
  font-size: 1.33em;
  white-space: nowrap;
}

#root table td.xlarge {
  font-size: 1.5em;
}

figure.segment img {
  width: auto;
  height: auto;
  max-width: 100%;
}

#user-images figure.segment img {
  cursor: pointer;
}

.container figure figcaption {
  position: absolute;
  left: 10%;
  bottom: 10%;
  right: 10%;
  font-size: 0.75rem;
  padding: 0.25em 2.5%;
  min-height: 10%;
  text-align: center;
  color: black;
  background: rgba(255, 255, 255, 0.75);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

figure figcaption div > span,
figure figcaption div > em,
figure figcaption div > i,
figure figcaption p > span,
figure figcaption p > span span,
figure figcaption p > em {
  display: inline-block;
  margin-left: 0.375em;
}

figure figcaption .tags {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: 0.25em 0 0.75em 0;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 0 0 0.25em 0;
  padding: 0 0 0.25em 0;
  border-bottom: dotted 1px white;
}

figure figcaption .tags li {
  margin: 0 0.5em 0 0;
  padding: 0 0.5em 0 0;
  list-style: none;
  border-right: solid 1px white;
}

figure figcaption .tags li::before {
  content: '#';
}

figure figcaption .tags li:last-child {
  padding: 0;
  border-right: none;
}

figure.segment figcaption p {
  clear: both;
  margin: 0;
  padding: 0 0 0.125em 0;
}

#root figure.segment:hover {
  -webkit-transform: scale(1.03333);
          transform: scale(1.03333);
  z-index: 50;
  cursor: pointer;
  background-color: white;
}

#root .user-images figure.segment.active:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

#root .textarea,
#root .react-tags-wrapper,
#root .textfield-long {
  width: 100%;
}

#root .react-tags .textfield-long input {
  min-width: 15em;
}

#root .top-bar .search-submit {
  margin: 0 0.75em 0 0.125em;
  color: #999999;
  color: var(--dark-grey);
}

#root .top-bar .inline-block {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

#root .top-bar .clear-search {
  z-index: 60;
  margin-left: -1.5em;
  opacity: 0.5;
}

#root .show-main .top-bar .clear-search {
  display: none;
}

#root .top-bar .clear-search:hover {
  opacity: 1;
}

#root .top-bar .search-submit:hover {
  color: #006532;
  color: var(--dark-green);
}

#root .textfield-long {
  margin: 0.5em 0;
}

#root input.textfield-long {
  border-width: 1px;
  border-color: gray;
}

#root .react-tags .textfield-long {
  margin: 0;
}

#root .cloudinary-button {
  width: 100%;
  font-size: 1em;
  text-transform: uppercase;
}

#root footer.footer {
  position: relative;
  width: 100%;
  clear: both;
}

#root footer.footer > section {
  min-height: 8em;
  padding: 1em 0;
}

footer.footer section.top {
  background-color: black;
}

footer.footer section.top a,
footer.footer section.top {
  color: white;
}

footer.footer section .inner-wrapper .inner {
  padding: 1em 5%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#root .cloudinary-button i.icon {
  margin-left: 0.5em;
  font-size: 1.25rem;
}

#root .react-tags-wrapper .ReactTags__tagInput {
  display: inline-block;
  position: relative;
  margin: 0.5em 0;
  color: black;
}

#root .react-tags-wrapper .ReactTags__suggestions {
  position: absolute;
  top: 1.75em;
  left: 0;
  background: white;
  padding: 0.25em 0.25 0.375em 0.25em;
  border: solid 1px #999999;
  z-index: 20;
}

.sortable-item,
#root .react-tags-wrapper .ReactTags__suggestions ul,
#root .react-tags-wrapper .ReactTags__suggestions li {
  list-style: none;
  margin: 0;
}

#root .react-tags-wrapper .ReactTags__suggestions ul {
  padding: 0;
}

#root .react-tags-wrapper .ReactTags__suggestions li {
  padding: 0.25em 0;
  min-width: 8em;
  cursor: pointer;
}

#root .actions i.save:hover,
#root .react-tags-wrapper .ReactTags__suggestions li:hover {
  color: #000099;
}

#root .react-tags-wrapper input {
  min-width: 10em;
}

#root .react-tags-wrapper .ReactTags__remove {
  font-size: 1.5rem;
  margin: 0 1em 0 0.375em;
  cursor: pointer;
  color: red;
}

.top-bar .ReactTags__selected {
  position: relative;
}

.top-bar .ReactTags__selected .ReactTags__tag {
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  z-index: 11;
}

.top-bar .ReactTags__selected .ReactTags__tagInput {
  width: 14em;
  border: solid 1px black;
}

.ReactTags__selected .ReactTags__tagInput input,
#root .ui.segments {
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.top-bar .ReactTags__selected .ReactTags__tagInput input {
  width: 100%;
  padding: 0 0.25em;
}

.top-bar.is-filtered .ReactTags__selected .ReactTags__tagInput input {
  opacity: 0;
}

.top-bar .bulk-actions {
  position: relative;
  margin-left: 5%;
}

.top-bar .bulk-actions .num-selected {
  margin: 0 1em 0 0;
}

.top-bar .bulk-actions i.icon {
  margin-left: 1em;
}

.message-pane {
  position: absolute;
  top: 6em;
  left: 0;
  right: 0;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.875);
  border-radius: 0.5em;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
}

.show-message .message-pane {
  z-index: 301;
  opacity: 1;
}

figure.segment:hover figcaption {
  opacity: 1;
}

.menu li strong,
.menu li em {
  display: inline-block;
}

.menu li em {
  margin-left: 0.5em;
}

.footer .logos {
  display: flex;
  width: 100%;
  max-height: 6em;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0.5em 0;
}

.footer .logos,
.footer .logos figure {
  position: relative;
}

.footer .logos figure {
  max-width: 30%;
  padding: 1em 5%;
}

.footer .logos figure img {
  position: relative;
  max-height: 4em;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.footer .top .inner {
  height: 10rem;
}

.footer h2 {
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto;
}

.footer .top .inner h2.side-title {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotate(270deg) translate(-10rem, 0.5rem);
          transform: rotate(270deg) translate(-10rem, 0.5rem);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  width: 10rem;
  overflow: hidden;
}

.footer .logos figure figcaption {
  position: absolute;
  left: -999em;
  top: 0;
}

.container .main-images,
.container .backdrop {
  position: fixed;
}

.container .backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  background-color: white;
  transition: opacity 0.75s ease-in-out;
  z-index: -1;
}

.container .backdrop {
  pointer-events: none;
}

.container.show-main .backdrop {
  pointer-events: all;
}

.show-main .backdrop {
  opacity: 1;
  z-index: 15;
}

.show-main #image-list {
  display: none;
}

#image-list figure .claimed-by {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.5em 2.25rem 0.25rem 1rem;
  opacity: 0;
  -webkit-user-select: none;
          user-select: none;
  transition: opacity 0.3333s ease-in-out;
  border-top: dotted 1px gray;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

#image-list figure .claimed-by .claimed-name {
  text-align: right;
  font-size: 0.9rem;
  font-weight: bold;
}

#image-list figure .claimed-by .claimed-name:hover {
  color: #006532;
  color: var(--dark-green);
}

#image-list figure .claimed-by .claimed-caption {
  text-align: left;
  font-size: 0.75rem;
}

#image-list figure:hover .claimed-by {
  opacity: 1;
}

#root p.status-message {
  position: absolute;
  top: 5rem;
  left: 25%;
  right: 25%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  font-weight: bold;
  z-index: 200;
}

.show-main .main-images {
  top: 2vw;
  left: 2vw;
  bottom: 2vw;
  right: 2vw;
  z-index: 52;
}

.show-main .main-images {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: row nowrap;
}

#root .main-images figure {
  object-fit: contain;
  object-position: center;
  position: absolute;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  transition: opacity 0.9s ease-in-out;
}

#root .ui.segments {
  margin: 0;
}

#root .main-images figure.show-xl {
  top: -2vw;
  left: -2vw;
  right: -2vw;
  bottom: -2vw;
}

#root .main-images figure.show-xl picture img {
  -webkit-transform: scale(2);
          transform: scale(2);
}

.show-main .main-images figure.inactive {
  pointer-events: none;
  opacity: 0;
}

.show-main .main-images figure.active {
  pointer-events: all;
  opacity: 1;
}

.show-main .main-images figure picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.show-main .main-images.stable figure.inactive {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.container .user-edit-form > .inner-wrapper {
  display: block;
  max-width: 40em;
  position: relative;
  margin: 0 auto;
}

.container .user-edit-form {
  opacity: 0;
  pointer-events: none;
}

.container.show-edit-form .user-edit-form {
  opacity: 1;
  pointer-events: all;
  z-index: 102;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.75);
}

.container .user-edit-form input.textfield-long,
.container .user-edit-form input.textfield-medium {
  width: 100%;
}

.container .user-edit-form select {
  clear: both;
}

.users-artists .user-edit-form {
  position: fixed;
  left: 0;
  right: 0;
  padding: 12em 0 0 0;
  margin: 0 auto;
}

.show-main .main-images figure img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: 0 auto;
}

.show-main .main-images figure .actions {
  position: absolute;
  top: -1rem;
  left: 0rem;
  display: flex;
  flex-flow: row nowrap;
  opacity: 0;
  padding: 0.25em;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.25em;
  height: 2.5em;
  transition: background-color 0.333s ease-in-out;
  -webkit-user-select: none;
          user-select: none;
}



#root .column .uri-group > .icon.plus {
  font-size: 1.25em;
  clear: both;
  margin: 0.5em 0;
  align-self: flex-end;
  color: #006532;
  color: var(--dark-green);
  cursor: pointer;
}

#root .column .row {
  display: flex;
  flex-flow: row nowrap;
}

#root .edit-panel .column {
  display: flex;
  flex-flow: column nowrap;
}

#root .column .uri-selector .uri > .icon {
  margin: .5em 0 0 .5em;
  cursor: pointer;
}

#root .column .uri-selector .uri .icon:hover {
  color: #990000;
}

#root .column .uri-selector .ui.selection.dropdown .menu {
  max-width: 8em;
}

#root .edit-panel .embed-group .embed-selector {
  display: grid;
  grid-template-areas: "left right"
    "bottom bottom";
  grid-template-columns: 1fr 5fr;
}

#root .edit-panel .embed-group .embed-selector > .title {
  grid-area: left;
}

#root .icon.trash {
  cursor: pointer;
}

#root .icon.trash:hover {
  color: #990000;
  color: var(--red-label);
}

#root .edit-panel .embed-group .embed-selector>.uri {
  grid-area: right;
}

#root .edit-panel .embed-group .embed-selector > p {
  grid-area: bottom;
}

#root .column .uri-selector,
#root .column .uri-selector .ui.input,
#root .column .uri-selector > .uri {
  width: 100%;
}

#root .column.uri-group > .icon.plus {
  align-self: flex-end;
  font-size: 1.25em;
  cursor: pointer;
}

#root .column.uri-group > .icon.plus:hover {
  color: #009900;
}

#root .column .uri-selector .ui.selection.dropdown .menu >.item {
  padding-right: 0;
}

.show-main .main-images figure .actions .status {
  margin: 0 0.5em 0 0;
}

.show-main .main-images figure .actions:hover {
  background-color: rgba(255, 255, 255, 1);
}

.show-main .main-images:hover figure.active .actions {
  opacity: 1;
}

.show-main .main-images figure .actions .icon {
  position: relative;
  margin-right: 0.25em;
  opacity: 0.5;
  font-size: 1.5rem;
  transition: all 0.333s ease-in-out;
}

.show-main .main-images figure .actions .icon:hover {
  opacity: 1;
}

.top-bar .bulk-actions .icon.up:hover,
.show-main .main-images figure .actions .icon.up:hover {
  color: green;
}

.show-main .main-images figure .actions .icon.right:hover {
  color: orange;
}

.top-bar .bulk-actions .icon.down:hover,
.show-main .main-images figure .actions .icon.trash:hover,
.show-main .main-images figure .actions .icon.down:hover {
  color: red;
}

.show-main .main-images figure figcaption .icon.download,
.show-main .main-images figure figcaption .icon.edit {
  font-size: 1.25rem;
  right: 0.125em;
}

.show-main .main-images figure figcaption .icon.edit {
  top: 0.125em;
}

.show-main .main-images figure figcaption .icon a {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: -1em;
  left: -0.5em;
  right: 0;
}

.show-main .main-images figure figcaption .icon.download {
  top: 2.125em;
}

.show-main .main-images figure figcaption .icon.edit:hover {
  color: #006532;
  color: var(--dark-green);
}

.main-images figure .edit-panel {
  z-index: -1;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  top: 4em;
  left: 20%;
  right: 20%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  max-height: calc(100vh - 4em);
  padding-bottom: 4em;
  overflow-y: auto;
}

.main-images figure.edit-mode .edit-panel {
  z-index: 128;
  opacity: 1;
  padding: 0.5em;
}

.show-main .main-images figure.edit-mode .edit-panel .actions {
  opacity: 1;
  left: auto;
  right: 1em;
  top: 0.5em;
}

#root .edit-panel .react-tags-wrapper .ReactTags__tagInput {
  margin: 0;
}

.show-main .main-images:hover figure.edit-mode figcaption,
.show-main .main-images figure.edit-mode figcaption {
  opacity: 0;
  pointer-events: none;
}

.show-main .main-images figure figcaption {
  max-height: calc(100vh - 10em);
  overflow-y: auto;
}

.actions.approved i.up,
.actions.referred i.right,
.actions.rejected i.down {
  display: none;
}

.show-main .main-images figure figcaption {
  z-index: 250;
}

.show-main .main-images:hover figure figcaption {
  opacity: 1;
}

.main-images figcaption > .icon,
.main-images > .icon {
  position: absolute;
  transition: all 0.33s ease-in-out;
  opacity: 0;
  cursor: pointer;
}

.main-images > .icon {
  font-size: 4vw;
}

.main-images .icon {
  opacity: 0.375;
}
.main-images:hover .icon {
  opacity: 0.625;
}

.main-images .icon:hover {
  opacity: 1;
}

.main-images .close {
  top: -3vw;
  right: -2vw;
  z-index: 56;
}

.main-images .arrow,
.main-images .arrow:before {
  position: absolute;
}

.main-images .arrow {
  top: 15%;
  bottom: 15%;
  font-size: 5vw;
  z-index: 55;
  width: 5%;
  height: 70%;
}

.main-images .arrow:before {
  top: 47.5%;
}

.main-im88ages .arrow.left {
  left: -2vw;
}

.main-images .arrow.left:before {
  left: 0;
}

.main-images .arrow.right {
  right: -2vw;
}

.main-images .arrow.right:before {
  right: 0;
}

.inner-content.top-content,
#column-images,
#masonry-grid {
  position: relative;
  clear: both;
  margin: 1em auto;
}

#column-images > figure.segment,
#masonry-grid .column > figure.segment {
  max-width: 100%;
  width: auto;
  flex: 1 1 auto;

  transition: -webkit-transform 0.5s ease-in-out;

  transition: transform 0.5s ease-in-out;

  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

#column-images > figure,
#column-images {
  display: flex;
  flex-direction: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#masonry-grid .column > figure.segment {
  padding: 4%;
}

#column-images > figure.segment {
  padding: 0;
  margin: 0 0 3vw 0;
}

.inner-content.top-content img {
  float: left;
  margin-right: 1em;
}

.inner-content.top-content > .text-content {
  min-height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

#masonry-grid figure figcaption {
  position: absolute;
  left: 2%;
  right: 2%;
  padding: 0.25em 0.5em;
  min-height: 5%;
  text-align: left;
  background: white;
  color: black;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

#masonry-grid figure:hover figcaption {
  opacity: 1;
}

#masonry-grid figure figcaption {
  bottom: 1%;
}

.main-images figure figcaption {
  bottom: 5%;
}

#masonry-grid.cols-1 > .column {
  width: 100%;
}

#masonry-grid.cols-2 > .column {
  width: 50%;
}

#masonry-grid.cols-3 > .column {
  width: 33.33%;
}

#masonry-grid.cols-4 > .column {
  width: 25%;
}

#masonry-grid.cols-5 > .column {
  width: 20%;
}

#masonry-grid.cols-6 > .column {
  width: 16.6666%;
}

.main-content .actions button {
  transition: opacity 0.5s ease-in-out;
}

.main-content .disabled button {
  pointer-events: none;
  opacity: 0.66667;
}



#root table tr.user-inactive {
  display: none;
}

#root .show-inactive table tr.user-inactive {
  display: table-row;
}

#root .show-inactive table tr.user-inactive td {
  color: red;
}

#root .actions .user.circle.outline {
  opacity: 0.5;
  -webkit-filter: grayscale(50%);
          filter: grayscale(50%);
}

#root .users-artists .actions em {
  display: inline-block;
  margin-right: 1em;
}

#root .users-artists .actions {
  margin-bottom: 0.5em;
}

#root .actions .user.circle.outline:hover,
#root .show-inactive .actions .user.circle.outline {
  color: red;
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

#root .actions .user.add:hover {
  color: green;
}

#root .show-inactive .actions .user.circle.outline {
  opacity: 1;
}



@media screen and (min-width: 440px) {
  #image-list figure.segment {
    max-width: 50%;
  }
}

@media screen and (min-width: 660px) {
  #image-list figure.segment {
    max-width: 33.333%;
  }
}

@media screen and (min-width: 880px) {
  #root header .main-nav i.bars {
    display: none;
  }

  #root .container .horizontal {
    -webkit-column-count: 4;
            column-count: 4;
  }

  article.post > .media,
  .right-col-offset .main-content .main-area {
    width: 50%;
    float: left;
  }

  article.post > .text-content,
  .right-col-offset .main-content > aside {
    width: 45%;
    float: right;
    top: -6em;
  }
  #root header nav.main-nav {
    position: relative;
    float: right;
    min-width: 45%;
    margin-left: 2%;
    max-width: 60%;
    overflow: hidden;
  }

  #root header nav.main-nav ul.menu {
    position: relative;
    top: 0;
    max-height: none;
    opacity: 1;
    pointer-events: all;
    text-align: left;
    overflow: visible;
    margin-left: 3rem;
    padding: 0;
  }
  #root header .user-details {
    margin: 0.25em 0;
  }
  #root footer.footer .footer-menu {
    display: flex;
    flex-flow: row nowrap;
  }
  #root footer.footer .footer-menu li {
    margin: 0.5em 1em;
  }

  #root .curation-edit-form #upload-widget {
    background-color: black;
    box-shadow: none;
    max-width: 15em;
    margin-left: 1em;
  }

  .curation-edit-form .curator-image {
    margin: 1em 0;
  }

  .inner-wrapper .top-bar {
    display: flex;
    flex-flow: row nowrap;
    max-height: 2em;
    width: 100%;
  }

  .inner-wrapper .top-bar label {
    display: inline-block;
    margin: 0 0.25em 0 0.75em;
    padding: 0.25em 0;
  }

  .inner-wrapper .top-bar > .close {
    margin: 0 0.75em 0 0.25em;
    padding: 0.125em;
    cursor: pointer;
  }

  .inner-wrapper .top-bar > .close:hover {
    color: red;
  }

  .inner-wrapper .top-bar ul.status-options {
    display: flex;
    flex-flow: row nowrap;
  }

  .inner-wrapper .top-bar h1 {
    font-size: 1.5em;
    white-space: nowrap;
    margin-right: 1em;
    width: 27%;
    overflow: hidden;
    height: 2em;
  }

  #root .inner-wrapper .top-bar > .react-tags-wrapper {
    width: 25%;
  }

  #image-list figure.segment {
    max-width: 25%;
  }

  .inner-wrapper .top-bar ul.status-options {
    white-space: nowrap;
    max-width: 50%;
    overflow: hidden;
    height: 2em;
    text-align: right;
    justify-content: flex-end;
  }
  .inner-wrapper .top-bar ul.status-options li {
    margin-left: 1em;
  }
  .container .main-images figure figcaption {
    left: 20%;
    right: 20%;
    text-align: left;
  }
  #root .react-tags-wrapper input {
    min-width: 12em;
  }

  .inner-wrapper table.celled tbody th {
    width: 13.5em;
    padding-left: 0.5em;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
  }
  .inner-wrapper table.celled tbody tr:first-child th {
    border-top: none;
  }

  .main-images .close,
  .main-images .arrow {
    font-size: 3vw;
  }

  .main-images .arrow:before {
    top: 48.5%;
  }

  .main-images .arrow.left {
    left: -1.75vw;
  }
  .main-images .arrow.right {
    right: -1.75vw;
  }
}

@media screen and (min-width: 1100px) {
  ul.main-menu li {
    font-size: 1.125em;
  }
  .inner-wrapper .top-bar h1 {
    width: 22%;
  }
  #image-list figure.segment {
    max-width: 20%;
  }
  .container .main-images figure figcaption {
    left: 25%;
    right: 25%;
  }
  #root .react-tags-wrapper input {
    min-width: 14em;
  }

  .inner-content.top-content > .text-content {
    max-width: 50em;
  }
}

@media screen and (min-width: 1320px) {
  ul.main-menu li {
    font-size: 1.375em;
  }

  #image-list figure.segment {
    max-width: 16.6667%;
  }
  .inner-wrapper .top-bar h1 {
    width: 18.6667%;
  }
  #root .inner-wrapper .top-bar > .react-tags-wrapper {
    min-width: 30%;
    width: 30%;
  }
  .container .main-images figure figcaption {
    left: 30%;
    right: 30%;
  }
  #root .top-bar .ReactTags__selected .ReactTags__tagInput {
    min-width: 16em;
    width: 16em;
  }

  #root .react-tags-wrapper input {
    min-width: 15.5em;
    width: 15.5em;
  }

  #root .top-bar .ReactTags__selected {
    padding-right: 0.5em;
  }
  #root .react-tags-wrapper .ReactTags__suggestions li {
    min-width: 18em;
  }
}

@media screen and (min-width: 1600px) {
  #column-images > figure.segment {
    margin-bottom: 48px;
  }
  ul.main-menu li {
    font-size: 1.5em;
  }
}


